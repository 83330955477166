import { ReactNode, useState } from 'react';
import { checked } from '../functions/Checked';

function Serviço() {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row'>
        <label>De:
          <input name='local1' className='mt-4 text-black border-2 border-black pl-4' placeholder='primeira localização' required />
          <text className='mr-4 ml-4'>a</text>
          <input name='local2' className='mt-4 text-black pl-4 border-2 border-black' placeholder='segunda localização' required />
        </label>
      </div>
      <label>Veículo:
        <select name='veiculo' className='mt-5 text-black w-72 border-2 border-black'>
          <option>Micro-Ônibus (001)</option>
          <option>Ônibus (002)</option>
        </select>
      </label>
      <div className='flex flex-col self-center'>
        <label>Data:
          <input name='data' type='date' className='text-black w-48 mt-5 border-2 border-black' required />
        </label>
        <button className='self-center hover:scale-110 duration-150 mt-5 bg-blue-800 rounded-3xl w-32 h-8 text-white'>Enviar</button>
      </div>
    </div >
  )
}
function Ajuda() {
  const [sobre, setsobre] = useState('a prestação de serviço');
  return (
    <div className='flex flex-col'>
      <div className='self-center'>
        <label>Sobre:
          <select onChange={() => setsobre((document.getElementById('sobre') as HTMLInputElement)!.value)} id='sobre' className='text-black mt-3 border-2 border-black' required >
            <option value={'a prestação de serviço'}>Serviço</option>
            <option value={'o site'}>Site</option>
            <option value={'o contato'}>contato</option>
            <option value={'a empresa'}>Empresa</option>
          </select>
        </label>
      </div>
      <textarea name='problem' className='text-black mt-2 border-2 border-black' rows={3} cols={50} placeholder={`Digite o problema, situação e como podemos te ajudar sobre ${sobre}`} required />
      <button className='self-center  hover:scale-110 duration-150 mt-5 bg-blue-800 text-white rounded-3xl w-32 h-8'>Enviar</button>
    </div>
  )
}
function Reclamações() {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  return (
    <div>
      <div className='flex flex-col self-center'>
        <label>Sobre:
          <select id='sobre' name='sobre' className='text-black mt-3 border-2 border-black'>
            <option value={'a prestação de serviço'}>Serviço</option>
            <option value={'o site'}>Site</option>
            <option value={'a equipe'}>Equipe ou funcíonario</option>
            <option value={'a empresa'}>Empresa</option>
          </select>
        </label>
        <text className='self-center mt-5'>Avaliação:</text>
        <div className='fex flex-row self-center'>
          <input id='checked1' checked={checked1} type='radio' className='mt-5 mr-5 w-6 h-6 text-red-800' name='estrela1' value={'sim'} onClick={(e) => checked(e, setChecked1, setChecked2, setChecked3, setChecked4, setChecked5)} />
          <input id='checked2' checked={checked2} type='radio' className='mt-5 mr-5 w-6 h-6' name='estrela2' value={'sim'} onClick={(e) => checked(e, setChecked1, setChecked2, setChecked3, setChecked4, setChecked5)} />
          <input id='checked3' checked={checked3} type='radio' className='mt-5 mr-5 w-6 h-6' name='estrela3' value={'sim'} onClick={(e) => checked(e, setChecked1, setChecked2, setChecked3, setChecked4, setChecked5)} />
          <input id='checked4' checked={checked4} type='radio' className='mt-5 mr-5 w-6 h-6' name='estrela4' value={'sim'} onClick={(e) => checked(e, setChecked1, setChecked2, setChecked3, setChecked4, setChecked5)} />
          <input id='checked5' checked={checked5} type='radio' className='mt-5 mr-5 w-6 h-6' name='estrela5' value={'sim'} onClick={(e) => checked(e, setChecked1, setChecked2, setChecked3, setChecked4, setChecked5)} />
        </div>
        <text className='self-center mt-5'>Comentário (opcional):</text>
        <textarea name='comment' className='text-black mt-5 border-2 border-black' />
        <button className='self-center  hover:scale-110 duration-150 mt-5 bg-blue-800 text-white rounded-3xl w-32 h-8'>Enviar</button>
      </div>
    </div>
  )
}
function Outros() {
  return (
    <div className='flex flex-col'>
      <text className='self-center text-2xl mt-3'>Nota:</text>
      <textarea name='message' className='text-black mt-2 border-2 border-black' rows={3} cols={50} placeholder='Digite aqui sua mensagem.' required />
      <button className='self-center  hover:scale-110 duration-150 mt-5 bg-blue-800 text-white rounded-3xl w-32 h-8'>Enviar</button>
    </div>
  )
}

function elementbody(value: string) {
  switch (value) {
    case 'serviço':
      return <Serviço />
    case 'ajuda':
      return <Ajuda />
    case 'reclamações':
      return <Reclamações />
    case 'outro':
      return <Outros />
  }
}

interface props {
  body: ReactNode
}

function Formbody(props: props) {
  return (
    <>
      {props.body}
    </>
  )
}

export { Formbody, elementbody }
