export function checked(element: any, setChecked1: any, setChecked2: any, setChecked3: any, setChecked4: any, setChecked5: any) {
  const e: number = parseInt(element.target.id[7]);
  for (let c: number = 1; c <= e; c++) {
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setChecked5(false);

    switch (c) {
      case 1:
        setChecked1(true);
        break;
      case 2:
        setChecked1(true);
        setChecked2(true);
        break;
      case 3:
        setChecked1(true);
        setChecked2(true);
        setChecked3(true);
        break;
      case 4:
        setChecked1(true);
        setChecked2(true);
        setChecked3(true);
        setChecked4(true);
        break;
      case 5:
        setChecked1(true);
        setChecked2(true);
        setChecked3(true);
        setChecked4(true);
        setChecked5(true);
        break;
    }
  }
}
