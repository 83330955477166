import { Link } from 'react-router-dom';
import '../../index.css';
interface props {
    section: string
    now: boolean
}
export default function Buttonsection(props: props) {
    const { opacity, pointer, hover } = {
        opacity: props.now === true ? 'opacity-50' : 'opacity-100',
        pointer: props.now === true ? 'cursor-default' : 'cursor-pointer',
        hover: props.now === true ? '' : 'hover:scale-110'
    };
    return (
        <Link to={`/${props.section}`}>
            <div className={`bg-blue-800 text-white rounded-2xl h-8 w-20 ${hover} flex items-center justify-center duration-150 ${pointer} ${opacity} hidden sm:flex`}>
                <text>{props.section}</text>
            </div>
        </Link>
    )
}
