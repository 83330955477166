import { FaSquareInstagram } from 'react-icons/fa6'

interface postsType {
  text: string,
  footer: string,
  url: string
}

export default function Posts() {
  const posts: Array<postsType> = [{ text: 'sempre a sua disposição!', footer: '💙12/10/2023 | post 1', url: 'https://firebasestorage.googleapis.com/v0/b/litoral-line-tour.appspot.com/o/Imagens%2FIMG_20230825_112146_041.jpg?alt=media&token=50794214-7ae3-4105-9eca-f5664429e9e5' }, { text: "A litoral line tour apoia o novembro azul! #secuide", footer: "💙08/11/2023 | post 2", url: "https://firebasestorage.googleapis.com/v0/b/litoral-line-tour.appspot.com/o/Imagens%2FIMG_20231103_123053_085.jpg?alt=media&token=a7aadd1a-234a-4569-8664-612a2d9b8199" }, { text: "Desejamos a todos um feliz natal!", footer: "💙18/11/2023 | post 3", url: "https://firebasestorage.googleapis.com/v0/b/litoral-line-tour.appspot.com/o/Imagens%2FIMG-20231118-WA0000.jpg?alt=media&token=f5437b4d-bc6b-4c47-81f8-6e8858e5e6c2" }];
  return (
    <div className='flex container justify-center mt-10 h-[34rem] overflow-scroll'>
      <ul>
        {posts.map((element) => <li><div className='flex flex-col justify-center w-80 sm:w-96 h-[30rem] border-4 border-blue-800 rounded-xl mb-5 bg-white'><img className='h-[25rem] w-[25rem] mt-[-0.8rem]' src={element.url} alt={element.text} /><h5 className='text-black text-[1.5rem] self-center'>{element.text}</h5><h2 className='text-black self-center'>{element.footer}</h2></div></li>)}
        <li><div className='flex justify-center rounded-xl bg-white text-xl text-black h-8 -mt-3'>Quer mais? Segue nosso <span className='underline text-blue-500 ml-1'><a href='https://www.instagram.com/litorallinetour/' target='_blank' rel="noreferrer"><div className='flex flex-row'><FaSquareInstagram className='mt-1' />Instagram!</div></a></span></div></li>
      </ul>
    </div>
  )
}
