import { FaSquareInstagram, FaEnvelope, FaSquareWhatsapp, FaSquareTwitter, FaSquareFacebook, FaAngleRight, FaHouse, FaBook, FaPhone, FaCircleInfo } from 'react-icons/fa6';
import Header from './components/Header';
import Zapbutton from './components/Zapbutton';
import img5 from '../images/img5.jpg';
import { Link } from 'react-router-dom';

export default function About() {
 return (
  <div>
   <Header sections={[false, false, false, true]} />
   <div className='ml-4 mt-6'>
    <h5 className='text-2xl'>A empresa <span className='underline'>Litoral line tour</span> é uma empresa de turismo e fretamento localizada em Paraty, RJ.</h5><h4 className='text-2xl'>Nós acreditamos em nossos sonhos e seguimos em frente! CNPJ:45.057.350/0001-22</h4>
    <text className='text-xl'>Nossas redes:</text>
    <h3 className='flex flex-row mt-2 text-[1rem] xl:text-[2rem]'><FaSquareInstagram className='text-[3rem] xl:text-[4rem] text-blue-800' />Instagram<FaAngleRight className='text-blue-800 text-[3rem] xl:text-[4rem] cursor-pointer hover:scale-110 duration-150' onClick={() => window.open('https://www.instagram.com/litorallinetour/')} /></h3>
    <h3 className='flex flex-row mt-2 text-[1rem] xl:text-[2rem]'><FaSquareWhatsapp className='text-[3rem] xl:text-[4rem] text-blue-800' />Whatsapp <FaAngleRight className='text-blue-800 text-[3rem] xl:text-[4rem] cursor-pointer hover:scale-110 duration-150' onClick={() => window.open('https://api.whatsapp.com/send/?phone=5524999272271&text&type=phone_number&app_absent=0')} /></h3>
    <h3 className='flex flex-row mt-2 text-[1rem] xl:text-[2rem]'><FaSquareTwitter className='text-[3rem] xl:text-[4rem] text-blue-800' />Twitter/X <FaAngleRight className='text-blue-800 text-[3rem] xl:text-[4rem] cursor-pointer hover:scale-110 duration-150' onClick={() => window.open('https://twitter.com/litoralline')} /></h3>
    <h3 className='flex flex-row mt-2 text-[1rem] xl:text-[2rem]'><FaSquareFacebook className='text-[3rem] xl:text-[4rem] text-blue-800' />Facebook <FaAngleRight className='text-blue-800 text-[3rem] xl:text-[4rem] cursor-pointer hover:scale-110 duration-150' onClick={() => window.open('https://www.facebook.com/people/Litorallinetour/100083959724731/')} /></h3>
    <h3 className='flex flex-row mt-2 text-[1rem] xl:text-[2rem]'><FaEnvelope className='text-[3rem] xl:text-[4rem] text-blue-800' />E-mail <FaAngleRight className='text-blue-800 text-[3rem] xl:text-[4rem] cursor-pointer hover:scale-110 duration-150' onClick={() => window.open('mailto:litorallinetour@protonmail.com')} /></h3>
   </div>
   <div className='flex container w-[50rem] flex-col items-end mt-[-10rem] ml-[-10rem] md:mt-[-18rem] md:ml-[0rem] xl:mt-[-23rem] xl:ml-[20rem]'>
    <img src={img5} alt='banner about' className='hidden md:w-[35rem] md:h-[20rem] md:flex xl:w-[50rem] xl:h-[23rem] rounded-2xl border-4 border-blue-800' />
    <text className='text-2xl self-center hidden md:flex'>"Litoral line tour, por que conhecer também é aprender!"</text>
   </div>
   <div id='sidebar' style={{ display: 'none', }} className='flex container z-30 justify-end items-end mt-[-26.5rem] text-white animate-sidebaranim'>
    <div className='flex items-center bg-blue-800 w-64 h-[98vh]'>
     <ul className='flex flex-col gap-8 text-4xl ml-4 -mt-20'>
      <Link to='/inicio'><li className='flex flex-row'><FaHouse /><FaAngleRight />Inicio</li></Link>
      <Link to='/blog'><li className='flex flex-row'><FaBook /><FaAngleRight />Blog</li></Link>
      <Link to='/contato'><li className='flex flex-row'><FaPhone /><FaAngleRight />Contato</li></Link>
      <Link to='/sobre'><li className='flex flex-row'><FaCircleInfo /><FaAngleRight />Sobre</li></Link>
     </ul>
    </div>
   </div>
   <Zapbutton />
  </div >
 )
}
