export default function Modal() {
  return (
    <div id='modal' style={{ display: 'none' }}>
      <div className='flex justify-center bg-black/50 w-screen h-screen z-10 mt-[-23rem]'>
        <div className='flex flex-col text-black justify-center items-center border-4 border-blue-800 bg-white h-80 w-[50rem] z-20 rounded-2xl mt-[15%]'>
          <h2 className='text-2xl'>Formulário de contato</h2>
          <h3 className='text-xl ml-10'>o formulário de contato é a maneira mais rapida e eficaz de contato através do email, prencha os campos com as informações espesificadas e então iremos receber um email com as mesmas e iremos entrar em contato com você para tratar do assunto selecionado.</h3>
          <h4>Obs: Se estiver procurando nossas redes sociais e outros meios de contato,dê uma olhada na aba sobre</h4>
          <div className='flex justify-center items-center bg-blue-800 text-white w-20 h-10 rounded-2xl cursor-pointer' onClick={() => document.getElementById('modal')!.style.display = 'none'}>Entendi</div>
        </div>
      </div>
    </div>
  )
}
