import { FaBars } from "react-icons/fa6";

export default function Sidebar() {
  return (
    <FaBars className='flex text-2xl sm:hidden' onClick={() => {
      document.getElementById('sidebar')!.style.display = 'flex';
      document.getElementById('zapbutton')!.style.display = 'none'
      if (document.getElementById('slider')! != null) {
        document.getElementById('slider')!.style.display = 'none';
      }
    }} />
  )
}
