import { useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { Formbody, elementbody } from './Formbody';
import { FaCheck } from 'react-icons/fa6';

export default function Formheader() {
  const [body, setbody] = useState(<text>selecione um assunto</text>);
  if (document.cookie) {
    return (
      <div className='flex flex-col h-[30rem] justify-center items-center text-2xl'>
        <FaCheck className='bg-green-600 rounded-2xl text-3xl text-white' />
        <h5>Email enviado com sucesso!</h5>
        <h4>entraremos em contato com você o mais rapido possível.</h4>
      </div>
    )
  }
  return (
    <form action={'https://formsubmit.co/litorallinetour@protonmail.com'} method='POST' onSubmit={() => { const d: Date = new Date(); d.setTime(d.getTime() + 50000); document.cookie = 'confirmform=true; expires=' + d.toUTCString(); }}>
      <div className='flex container flex-col items-center mt-20'>
        <div className='flex fleex-row items-center'><text className='text-2xl sm:text-4xl'>Entrar em contato com a empresa</text><FaCircleInfo className='hidden md:flex text-blue-800 text-2xl ml-2 cursor-pointer' onClick={() => document.getElementById('modal')!.style.display = 'flex'} /></div>
        <label className='text-xl'>Email:
          <input name='email' className='border-2 dark:text-black border-black w-72 h-8 text-[1.3rem] mb-4 p-1' type='email' placeholder='Digite seu email.' required />
        </label>
        <label className='text-xl'>Nome:
          <input name='nome' className='border-2 dark:text-black border-black w-72 h-8 text-[1.3rem] mb-4 p-1' type='name' placeholder='Digite seu nome (completo).' required />
        </label>
        <label>Assunto:
          <select name='assunto' id='assunto' onChange={() => setbody(elementbody((document.getElementById('assunto') as HTMLInputElement).value)!)} className='border-2 border-black text-black' required >
            <option>Selecionar</option>
            <option value={'serviço'}>serviço</option>
            <option value={'ajuda'}>ajuda/suporte</option>
            <option value={'reclamações'}>reclamações</option>
            <option value={'outro'}>outro</option>
          </select>
        </label>
        <Formbody body={body} />
      </div>
      <input type="hidden" name="_next" value="https://litorallinetour.com.br/contato" />
    </form >
  )
}
