import Header from './components/Header';
import Zapbutton from './components/Zapbutton';
import Posts from './components/Posts';
import { Link } from 'react-router-dom';
import { FaAngleRight, FaBook, FaPhone, FaCircleInfo, FaHouse } from 'react-icons/fa6';
export default function Blog() {
 return (
  <div>
   <Header sections={[false, true, false, false]} />
   <Posts />
   <Zapbutton />
   <div id='sidebar' style={{ display: 'none', }} className='flex container z-30 justify-end items-end  mt-[-40.5rem] text-white animate-sidebaranim'>
    <div className='flex items-center bg-blue-800 h-32 w-64 h-[98vh]'>
     <ul className='flex flex-col gap-8 text-4xl ml-4 -mt-20'>
      <Link to='/inicio'><li className='flex flex-row'><FaHouse /><FaAngleRight />Inicio</li></Link>
      <Link to='/blog'><li className='flex flex-row'><FaBook /><FaAngleRight />Blog</li></Link>
      <Link to='/contato'><li className='flex flex-row'><FaPhone /><FaAngleRight />Contato</li></Link>
      <Link to='/sobre'><li className='flex flex-row'><FaCircleInfo /><FaAngleRight />Sobre</li></Link>

     </ul>
    </div>
   </div>
  </div>
 )
}
