import Buttonsection from './Buttonsection';
import { Link } from 'react-router-dom';
import img1 from '../../images/img1.png';
import Sidebar from './Sidebar';
import '../../index.css';

interface Props {
    sections: Array<boolean>
}

export default function Header(props: Props) {
    return (
        <div>
            <header className='flex justify-between m-5'>
                <Link to={'/'}>
                    <img src={img1} alt='logo' className='h-8 dark:bg-white rounded-md sm:h-10' />
                </Link>
                <div className='flex gap-5 mr-5'>
                    <Buttonsection section='inicio' now={props.sections[0]} />

                    <Buttonsection section='blog' now={props.sections[1]} />
                    <Buttonsection section='contato' now={props.sections[2]} />
                    <Buttonsection section='sobre' now={props.sections[3]} />
                    <Sidebar />
                </div>
            </header>
            <div className='bg-black dark:bg-blue-800 h-2 w-screen sm:h-3 -mt-3'></div>
        </div>
    )
}
