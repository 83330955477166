import { FaWhatsapp } from 'react-icons/fa6';

export default function Zapbutton() {
  return (
    <div id='zapbutton' className='flex container justify-end'>
      <div className='flex fixed justify-center items-center z-10 bottom-10 bg-blue-800 text-white w-20 h-20 rounded-full cursor-pointer' onClick={() => window.open('https://api.whatsapp.com/send/?phone=5524999272271&text&type=phone_number&app_absent=0')}>
        <FaWhatsapp className='flex text-5xl' />
      </div>
    </div>
  )
}
