import React from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import img1 from '../images/img1.png';
import Slider from './components/Slider';
import Zapbutton from './components/Zapbutton';
import { FaAngleRight, FaBook, FaPhone, FaCircleInfo, FaHouse } from 'react-icons/fa6';
import '../index.css';

export default function Home() {
      return (
            <div>
                  <Header sections={[true, false, false, false]} />
                  <section className='flex flex-col'>
                        <div className='flex flex-col w-100 items-center mt-36 lg:items-end'>
                              <img src={img1} alt='logo' className='h-20 mt-[-6rem] dark:bg-white rounded-md lg:mr-40 lg:mt-[0rem]' />
                              <Link to={'/contato'}>
                                    <div className='flex bg-blue-800 text-white text-2xl rounded-3xl h-12 w-36 items-center justify-center lg:mr-64 mt-6 hover:scale-110 duration-150'>contate-nos</div>
                              </Link>
                        </div>
                        <Slider />
                  </section>
                  <div id='sidebar' style={{ display: 'none', }} className='flex container z-30 justify-end items-end mt-[-16.5rem] text-white animate-sidebaranim'>
                        <div className='flex items-center bg-blue-800 w-64 h-[98vh]'>
                              <ul className='flex flex-col gap-8 text-4xl ml-4 -mt-20'>
                                    <Link to='/inicio'><li className='flex flex-row'><FaHouse /><FaAngleRight />Inicio</li></Link>
                                    <Link to='/blog'><li className='flex flex-row'><FaBook /><FaAngleRight />Blog</li></Link>
                                    <Link to='/contato'><li className='flex flex-row'><FaPhone /><FaAngleRight />Contato</li></Link>
                                    <Link to='/sobre'><li className='flex flex-row'><FaCircleInfo /><FaAngleRight />Sobre</li></Link>
                              </ul>
                        </div>
                  </div>
                  <Zapbutton />
            </div>
      );
}
