import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import Blog from './pages/Blog'
import Home from './pages/Home';
import Contate from './pages/Contate';
import About from './pages/About';
import Funcionario from './pages/Funcionario';

const root = ReactDOM.createRoot(
 document.getElementById('root') as HTMLElement
);

const Routers = createBrowserRouter([
 {
  path: '/',
  loader: () => { return redirect('/inicio'); },
 },
 {
  path: '/inicio',
  element: <Home />
 },
 {
  path: '/blog',
  element: <Blog />
 },
 {
  path: '/contato',
  element: <Contate />
 },
 {
  path: '/sobre',
  element: <About />
 },
 {
  path: '/funcionario/:id',
  element: <Funcionario />
 }
])

root.render(
 <React.StrictMode>
  <RouterProvider router={Routers} />
 </React.StrictMode>
);
