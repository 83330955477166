import img2 from '../../images/img2.jpg';
import img3 from '../../images/img3.jpg';
import img4 from '../../images/img4.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Slider() {
    return (
        <div id='slider' className='flex self-center w-[23rem] h-[18rem] mt-[2rem] dark:border-4 border-white lg:self-start lg:ml-56 lg:mt-[-12rem]'>
            <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }}>
                <SwiperSlide><img src={img2} alt='Micro Ônibus (1)' className='h-[18rem] w-[23rem]' /></SwiperSlide>
                <SwiperSlide><img src={img3} alt='Micro Ônibus (2)' className='h-[18rem] w-[23rem]' /></SwiperSlide>
                <SwiperSlide><img src={img4} alt='Micro Ônibus (3)' className='h-[18rem] w-[23rem]' /></SwiperSlide>
                <SwiperSlide><iframe className='-mt-2' width="380" height="350" src="https://www.youtube.com/embed/OSTqhuVxLDA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></SwiperSlide>
            </Swiper>
        </div>
    )
}
